import React from 'react'
import * as Chakra from '@chakra-ui/react'
import PropTypes from "prop-types";
import Highlight from 'react-highlight'

export const HTMLContent = ({ content }) => (
    <Chakra.Box className="post-content ">
      <Highlight className="javascript" innerHTML={true}>{content}</Highlight>
    </Chakra.Box>
)

const Content = ({ content }) => (
  <Chakra.Box className="post-content" >{content}</Chakra.Box>
)

Content.propTypes = {
  content: PropTypes.node,
  className: PropTypes.string,
}

HTMLContent.propTypes = Content.propTypes

export default Content
