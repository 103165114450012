import React from 'react'
import PropTypes from 'prop-types'
import { kebabCase } from 'lodash'
import { Helmet } from 'react-helmet'
import { graphql, Link } from 'gatsby'
import Content, { HTMLContent } from '../components/Content'
import {Seo} from "../components/seo";
import * as Chakra from '@chakra-ui/react'
import Highlight from 'react-highlight'


export const GuideTemplate = ({
  content,
  contentComponent,
  description,
  tags,
  title,
    date,
}) => {
  const PostContent = contentComponent || Content

  return (
    <section className="section">
      <Chakra.Heading py="60px" maxWidth="800px" px="40px" m="auto" textAlign="center">
        {title}
      </Chakra.Heading>
        <Chakra.Flex alignItems="center" justifyContent="Center">
            <Chakra.Text textAlign="center" mb="20px" fontSize="xs" fontWeight="bold" backgroundColor="black" color="#fff" px="20px" py="8px">{date}</Chakra.Text>
        </Chakra.Flex>
      <Chakra.Box px="20px" maxWidth="1400px" border="1px solid #ccc" borderRadius="8px" mb="40px">
          <Chakra.Box maxWidth="760px" py="40px" px="20px" m="auto" textAlign="center">
              {description}
          </Chakra.Box>
      </Chakra.Box>
      <PostContent content={content} />
      {tags && tags.length ? (
          <Chakra.Box py="60px" maxWidth="800px" px="40px" m="auto" textAlign="left">
              <Chakra.Heading pb="20px" size="md" width="100%">Tags</Chakra.Heading>
              <Chakra.HStack spacing="10px">
                  {tags.map((tag) => (
                      <Chakra.Button as={Link} key={tag + `tag`} to={`/tags/${kebabCase(tag)}/`}>
                          {tag}
                      </Chakra.Button>
                  ))}
              </Chakra.HStack>

          </Chakra.Box>
      ) : null}
    </section>
  )
}

GuideTemplate.propTypes = {
  content: PropTypes.node.isRequired,
  contentComponent: PropTypes.func,
  description: PropTypes.string,
  title: PropTypes.string,
  helmet: PropTypes.object,
}

const Guide = ({ data, location }) => {
  const { markdownRemark: post } = data

  return (
      <>
        <Seo title={post.frontmatter.title} description={post.frontmatter.description} location={location} />
        <GuideTemplate
          content={post.html}
          contentComponent={HTMLContent}
          description={post.frontmatter.description}
          tags={post.frontmatter.tags}
          title={post.frontmatter.title}
          date={post.frontmatter.date}
        />
        </>
  )
}

Guide.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.object,
  }),
}

export default Guide

export const pageQuery = graphql`
  query GuideByID($id: String!) {
    markdownRemark(id: { eq: $id }) {
      id
      html
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        title
        description
        tags
      }
    }
  }
`
